.u-text-center {
  text-align: center;
}

.u-text-right {
  text-align: right;
}

.u-text-left {
  text-align: left;
}

@each $bp, $config in $breakpoints-map {
  @include respond-to($bp) {
    .u-text-center--#{$bp} {
      text-align: center;
    }

    .u-text-right--#{$bp} {
      text-align: right;
    }

    .u-text-left--#{$bp} {
      text-align: left;
    }
  }
}
