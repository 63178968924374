@each $color, $value in $colours-map {
  .u-text-color--#{$color} {
    color: $value;

    a {
      @include link() {
        color: $value;
      }

      @include link-hover() {
        color: darken($value, 10%);
      }
    }
  }
}
