// ==========================================================================
// Masthead Molecule
// ==========================================================================

.m-masthead {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: palette(grey-dark);

  @include respond-to(medium) {
    display: block;
    padding-top: spacing(base);
    padding-top: spacing(medium);
  }

  @media print {
    display: none;
  }
}


.m-masthead-nav-container {
  position: relative;
  width: 100%;
}