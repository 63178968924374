// ==========================================================================
// Select Form Atom
// ==========================================================================

.select {
  display: block;
  box-sizing: border-box;
  padding: spacing(13 15);
  border: 2px solid lighten(palette(black), 60%);
  margin-bottom: rem-calc(5);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: white;
  border-radius: rem-calc(3);
  font-family: -apple-system, "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: ms(0, $thread: desktop);
  outline: none;
}

.select__wrapper {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  background-color: white;
  vertical-align: middle;

  .select {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: spacing(13 30 13 15);
    border: 2px solid lighten(palette(black), 60%);
    margin-bottom: rem-calc(5);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: rem-calc(3);
    font-family: -apple-system, "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: ms(0, $thread: desktop);
    outline: none;


    &:focus {
      border-color: palette(primary);
    }
  }

  .select::-ms-expand {
    display: none;
  }
}

// icon
.select__icon {
  position: absolute;
  z-index: 1;
  top: rem-calc(14);
  right: rem-calc(15);
  pointer-events: none;
}
