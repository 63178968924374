// ==========================================================================
// Clearfix Tool
// ==========================================================================

@mixin clearfix {
  &::after {
    display: table;
    clear: both;
    content: "";
  }
}
