// ==========================================================================
// Callout Atom
// ==========================================================================

.callout {
  @include trim();
  padding: spacing(medium);

  border-left: 4px solid palette(primary);
  margin-top: spacing(medium);
  margin-bottom: spacing(medium);
  background-color:  palette(grey);

  @include respond-to(medium) {
    padding: spacing(large);
  }

  > *:first-child {
    padding-top: 0;
    margin-top: 0;
  }

  > *:last-child {
    padding-bottom: 0;
    margin-bottom: 0;

    li:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}

.callout--clear {
  border: 1px solid palette(grey);
  background-color: transparent;
}