// ==========================================================================
// Checkbox Atom
// ==========================================================================

.checkbox {
  position: absolute;
  left: -999em;

  & + label {
    position: relative;
    display: block;
    padding-top: spacing(1);
    padding-left: rem-calc(26);
    text-align: left;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: rem-calc(13);
      height: rem-calc(13);
      border: rem-calc(2) solid lighten(palette(black), 80%);
      content: "";
    }

    &:hover {
      cursor: pointer;
    }
  }

  & + label::before {
    display: inline-block;
    width: rem-calc(14);
    height: rem-calc(14);
    border: 2px solid palette(primary);
    margin-top: rem-calc(3);
    margin-right: 10px;
    background: white;
    border-radius: rem-calc(3);
    content: "";
    vertical-align: text-top;
  }

  &:checked + label::after {
    position: absolute;
    top: rem-calc(10);
    left: rem-calc(3);
    width: 3px;
    height: 3px;
    background: palette(primary);
    box-shadow:
      2px 0 0 palette(primary),
      4px 0 0 palette(primary),
      4px -2px 0 palette(primary),
      4px -4px 0 palette(primary),
      4px -6px 0 palette(primary),
      4px -8px 0 palette(primary);
    content: "";
    transform: rotate(45deg);

    @include respond-to(medium) {
      top: rem-calc(10);
    }
  }

  &:focus + label {
    color: palette(primary);
  }

  &:checked + label {
    text-decoration: line-through;
  }
}

form {
  .checkbox {
    &:checked + label {
      text-decoration: none;
    }
  }
}


