// ==========================================================================
// Masthead Nav Molecule
// ==========================================================================

.m-masthead-nav {
  margin-top: spacing(15);
  background: palette(grey-dark);
}

.m-masthead-nav__item {
  @include link() {
    display: inline-block;
    padding: spacing(20 13);
    border-bottom: 4px solid transparent;
    margin-right: spacing(2);
    color: palette(white);
    font-size: ms(0);
    font-weight: bold;
    text-decoration: none;

    &:last-of-type {
      margin-right: 0;
    }

    &.is--active {
      position: relative;
      border-top: 4px solid palette(secondary);
      background-color: palette(grey-off-dark);
      color: white;
      font-weight: 600;
      text-align: center;
      text-decoration: none;
      transition: all ease 150ms;
    }
  }

  @include link-hover {
    color: tint(palette(white), "darken");
  }
}
