// ==========================================================================
// Hero Molecule
// ==========================================================================

.m-hero__wrapper {
  padding-bottom: spacing(base);

  @include respond-to(medium) {
    padding-bottom: spacing(xlarge);
  }
}

.m-hero__wrapper--bg {
  position: relative;
  background-position: center center;
  background-size: cover;

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: palette(grey-off-dark);
    content: "";
    opacity: 0.35;
  }
}

.m-hero {
  padding-top: spacing(45);

  // @include respond-to(medium) {
  //   padding-top: spacing(45);
  // }
}

.m-hero__title {
  font-size: ms(4);

  @include respond-to(medium) {
    margin: 1em 0 0.25em;
    font-size: ms(4, $thread: desktop);
  }
}

// If the breadcrumbs are enabled we want to space the pill away from it
.m-breadcrumb__wrapper + .m-hero {
  .pill {
    margin-top: spacing(8);

    @include respond-to(medium) {
      margin-top: spacing(15);
    }
  }
}
