// ==========================================================================
// WRAPPER LAYOUT
// ==========================================================================

// CONFIG + SETTINGS
// Max-width of the wrapper module regardless of the breakpoint,
$_wrapper-max-width: 1200;

// Gutter width of the wrapper to aid legibility on smaller devices.

.wrapper {
  position: relative;
  width: 100%;
  max-width: rem-calc($_wrapper-max-width);
  padding-right: spacing(base);
  padding-left: spacing(base);
  margin: 0 auto;
  clear: both;
}

.wrapper--medium {
  @include respond-to(medium) {
    position: relative;
    width: 100%;
    max-width: rem-calc($_wrapper-max-width);
    padding-right: spacing(base);
    padding-left: spacing(base);
    margin: 0 auto;
    clear: both;
  }
}