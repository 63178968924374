@mixin trim() {
  > *:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

@mixin trim-top() {
  > *:last-child {
    padding-top: 0;
    margin-top: 0;
  }
}

@mixin trim-all() {
  > *:last-child {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.u-trim {
  @include trim;
}

.u-trim--top {
  @include trim-top;
}

.u-trim--all {
  @include trim-all;
}
