
$colours-map: (
  /* Grey Off Dark - Example Usage: Contrast in masthead */
  'grey-off-dark': (rgb(60, 60, 60)),
  /* Primary purple: - Example Usage: Used as primary link colour and CTA quick edit bar */
  'primary': (rgb(114, 46, 165)),
  /* Success Green */
  'success': (rgb(13, 188, 55)),
  /* Grey Dark - Example Usage: Header and footer background */
  'grey-dark': (rgb(51, 51, 51)),
  /* Grey: - Example Usage: Pattern nav dividers and active state */
  'grey-light': (rgb(232, 232, 234)),
  /* Pure White */
  'white': (rgb(255, 255, 255)),
  /* Accent pink: - Example Usage: masthead nav active state */
  'accent': (rgb(230, 0, 124)),
  /* Secondary teal: - Example Usage: */
  'secondary': (rgb(29, 211, 167)),
  /* Warning Red */
  'warning': (rgb(214, 3, 3)),
  /* Grey: - Example Usage: Section divider background and dividing lines lines */
  'grey': (rgb(243, 243, 244)),
  /* Pure Black */
  'black': (rgb(0, 0, 0)),
);
