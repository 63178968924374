// ==========================================================================
// Logo Atom
// ==========================================================================

.logo {
  fill: white;

  @include link-hover() {
    opacity: 0.85;
  }
}

.logo--black {
  fill: black;
}
