/* Algolia search styling */

$_searchbox-width: 280;

.ais-InstantSearch {
  background-color: white;

  @include respond-to(medium) {
    background-color: transparent;
  }
}

.ais-SearchBox {
  padding: spacing(base base 0 base);

  @include respond-to(medium) {
    padding: 0;
  }
}

.ais-SearchBox-form {
  position: relative;
  width: rem-calc($_searchbox-width);
  max-width: 100%;

  @include respond-to(medium) {
    position: absolute;
    top: spacing(20);
    right: spacing(20);
  }

  @include respond-to(xlarge) {
    right: calc(50vw - #{rem-calc(600)} + #{rem-calc(26)})
  }

}

.ais-SearchBox-input {
  width: rem-calc($_searchbox-width);
  max-width: 100%;
  height: rem-calc(41);
  padding: spacing(10 10 8);
  border: 1px solid palette(grey-light);
  background-color: white;
  border-radius: 2px;
  font-size: ms(-1);
  line-height: 1.44;
}

.ais-SearchBox-submit,
.ais-SearchBox-reset {
  position: absolute;
  top: 0;
  right: 0;
  height: rem-calc(41);
  padding: spacing(11 20 7);
  border: solid 2px palette(primary);
  background: palette(primary);
  background-image: linear-gradient(to bottom, tint(palette(primary), "lighten"), palette(primary));
  border-radius: 0px 2px 2px 0px;

  @include link-hover() {
    border-color: tint(palette(primary), "darken");
    background: tint(palette(primary), "darken");
  }

  svg {
    width: rem-calc(14);
    height: rem-calc(14);
    fill: white;
  }
}

.ais-Hits {
  padding: spacing(0 base base base);
  background-color: white;

  @include respond-to(medium) {
    padding-top: spacing(base);
  }
}

.ais-Hits-list {
  padding: 0;
  @extend .wrapper;
}

.ais-Hits-item {
  padding-bottom: spacing(base);
  padding-left: 0;

  border-bottom: 1px solid palette(grey);

  &:before {
    display: none;
  }

  a {
    color: black;
    text-decoration: none;
  }
}

.ais-Hits-title {
  color: palette(primary);
  text-decoration: underline;
  transition: color 250ms;

  @include link-hover() {
    color: black;
  }
}

.ais-Snippet {
  mark {
    background-color: palette(primary);
    color: white;
  }
}

.ais-NoResults {
  background-color: white;
}

.ais-PoweredBy {
  padding-bottom: spacing(base);
  background-color: white;
  text-align: center;
}