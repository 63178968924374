// ==========================================================================
// Links Atom
// ==========================================================================
a,
.link {
  text-decoration: underline;
  text-decoration-skip: ink;
  transition: color 250ms;

  @include link() {
    color: palette(primary);
  }

  @include link-hover() {
    color: palette(grey-dark);
  }
}

a,
.link,
button {
  &:focus {
    outline: 1px dotted palette("secondary");
  }

  &:active {
    outline: none;
  }
}

.link--block {
  display: block;
}

.link--bold {
  font-weight: 600;
}

.link--plain {
  @include link-hover() {}

  text-decoration: none;
  text-decoration-skip: none;

  @include link() {
    color: palette(black);
  }
}

.link--black {
  @include link() {
    color: palette(black);
  }

  @include link-hover() {
    color: palette(primary);
  }
}

.link--white {
  @include link() {
    color: palette(white);
  }

  @include link-hover() {
    color: tint(palette(white), "darken");
  }
}

.link--secondary {
  @include link() {
    color: palette(secondary);
  }

  @include link-hover() {
    color: tint(palette(secondary), "darken");
  }
}

.link--break {
  word-break: break-word;
}
