.m-accordion-nav {
  width: 100%;
  border-bottom: 1px solid palette(grey-light);
}

.m-accordion-nav__item {
  @include link {
    display: block;
    padding: spacing(20 15);
    border-top: 1px solid palette(grey-light);
    margin: 0;
    color: palette(black);
    font-weight: bold;
    text-decoration: none;
    transition: all 250ms;
  }

  @include link-hover {
    background-color: palette(grey-light);
    color: palette(primary);

    .chevron {
      border-top-color: palette(primary);
    }
  }

  &.is--active {
    @include link {
      background-color: palette(grey-light);
    }

    .chevron {
      transform: rotate(180deg);
    }
  }

  .chevron {
    border-top-color: palette(black);
    margin: rem-calc(10 15 0 0);
    float: right;
    transition: all 250ms;
  }
}

.m-accordion-nav--level2 {
  display: none;
  font-size: ms(-1);

  &.is--open {
    display: block;
  }

  .m-accordion-nav__item {

    @include link {
      padding: spacing(10 15 10 25);
      color: palette(primary);
    }

    .chevron {
      border-top-color: palette(primary);
      margin: rem-calc(6 4 0 0);
      float: left;
    }

    &.is--active {
      @include link {
        background-color: palette(primary);
        color: white;
        font-weight: bold
      }

      .chevron {
        border-top-color: palette(white);
        transform: rotate(-90deg);
      }
    }

  }
}
