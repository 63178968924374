.u-sticky {
  position: sticky;
}

.u-sticky--top {
  top: 0;
}

.u-sticky--bottom {
  bottom: 0;
}

.u-sticky--left {
  left: 0;
}

.u-sticky--right {
  right: 0;
}

.u-absolute {
  position: absolute;
}

.u-relative {
  position: relative;
}