// ==========================================================================
// Nav Mobile Molecule
// ==========================================================================

.m-nav-mobile {
  position: relative;
  z-index: 1;
  padding: spacing(20 25);
  background: palette(white);
}


.m-nav-mobile__toggled {
  display: none;

  &.is--open {
    display: block;
  }

  @include respond-to(medium) {
    display: block;
  }

}

.m-nav-mobile__item {
  display: block;

  @include link() {
    padding: spacing(7 0);
    color: palette(primary);
    font-size: ms(5);
    font-weight: bold;
  }

  &.is--active {
    @include link() {
      color: palette(grey-dark);
      text-decoration: none;
    }
  }
}

.m-nav-mobile__separator {
  border-top: 1px solid palette(grey);
  border-bottom: none;
  margin: 12px 0;
  border-radius: 0;
}
