.m-accordion__content {
  display: none;

  &.is--open,
  .no-js & {
    display: block;
  }
}

.m-accordion__title {
  @include link() {
    display: block;
    padding: spacing(10 0);
    border-bottom: 1px solid palette(grey);
    color: palette(primary);
    text-decoration: none;
  }

  @include link-hover() {
    color: tint(palette(primary), "darken");
  }

  .chevron {
    margin: rem-calc(6 0 0 0);
    float: left;
  }
}

.m-accordion__title-text {
  padding-left: rem-calc(18);
  margin: 0;
}
