aside {
  padding-top: spacing(base);

  @include respond-to(medium) {
    padding-top: none;
  }
}

aside > .island > .pad-top > *:first-child {
  padding-top: 0;
  margin-top: 0;
}

aside > .island {
  border-top: 2px solid darken(palette(grey), 4%);
  border-bottom: 2px solid darken(palette(grey), 4%);
}

aside {
  nav {
    .nav-item {
      @include link() {
        @include respond-to(medium) {
          font-size: ms(-1);
        }

        @include respond-to(large) {
          font-size: ms(0);
        }
      }
    }
  }
}
