// ==========================================================================
// Island Atom
// ==========================================================================

.island {
  overflow-x: hidden;
}

// Loop through each colour and generate a island for each
@each $color, $value in $colours-map {
  .island--#{$color} {
    background-color: $value;
  }
}