// ==========================================================================
// Brand Atom
// ==========================================================================

.brand {
  @include link() {
    display: inline-block;
    padding: spacing(18) spacing(base);
    margin: 0;
    color: white;
    font-size: ms(1);
    font-weight: bold;
    line-height: 1.15;
    text-decoration: none;

    @include respond-to(medium) {
      padding: 0;
      font-size: ms(4, $thread: desktop);
    }
  }
}
