.m-person + .m-person {
  margin-top: spacing(base);
}

.m-person__grid {
  display: flex;
  align-items: center;
}

.m-person__avatar {
  max-width: rem-calc(50);
  max-height: rem-calc(50);
  margin-right: rem-calc(15);
  border-radius: 100%;
}

.m-person__title-header {
  font-weight: bold;
}

.m-person__social {
  margin-top: spacing(15);
}

.m-person__social-link {
  display: block;
  margin-top: spacing(5);
  font-size: ms(0, $thread: desktop);

  a {
    font-weight: bold;
  }
}
