// ==========================================================================
// CTA Tray Molecule
// ==========================================================================

.m-cta {
  padding: spacing(15 0 13);
  background: palette(primary);
  color: white;

  @media print {
    display: none;
  }
}

.m-cta--white {
  background: palette(white);
  color: palette(black);

  a {
    @include link() {
      color: palette(primary);
    }
  }
}
