.m-modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  display: none;
  width: 100%;
  height: 100%;
  align-items: center;
  background: rgba(255, 255, 255, 0.85);

  &.is--open {
    display: block;

    @include respond-to(medium) {
      display: flex;
    }
  }

  > .wrapper {
    max-width: 100%;
    padding: 0;
    margin: 0;

    @include respond-to(medium) {
      padding-right: rem-calc(25);
      padding-left: rem-calc(25);
    }
  }
}

// Class to apply to body (inner wrapper) when modal is active...


.m-modal__inner {
  position: relative;
  max-width: rem-calc(900);
  height: 100vh;
  max-height: calc(100vh);
  padding: spacing(base);
  padding: spacing(40 25);
  margin: 0 auto;
  background: white;
  box-shadow: 0 2px 65px 0 rgba(0, 0, 0, 0.22);
  overflow-x: scroll;

  @include respond-to(medium) {
    height: 100%;
    max-height: calc(90vh);
    padding: spacing(50 90);
    border-radius: rem-calc(6);
  }

  @include respond-to(large) {
    max-height: calc(85vh);
    padding: spacing(80 125);
  }
}

.m-modal__close {
  position: absolute;
  top: rem-calc(12);
  right: rem-calc(10);
  display: block;
  width: rem-calc(50);
  height: rem-calc(50);
  background: rgba(255, 255, 255, 0);
}

.m-modal__close::before,
.m-modal__close::after {
  position: absolute;
  top: 17%;
  left: 45%;
  width: rem-calc(2);
  height: rem-calc(30);
  background-color: palette(black);
  content: " ";
}

.m-modal__close::before {
  transform: rotate(45deg);
}

.m-modal__close::after {
  transform: rotate(-45deg);
}

.m-modal__close:hover {
  &::before,
  &::after {
    background-color: palette(primary);
  }
}
