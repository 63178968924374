// ==========================================================================
// Paragraph Atom
// ==========================================================================

p {
  padding: 0;
  margin: 1em 0;
  font-size: ms(0);
  line-height: 1.44;
}

p.small {
  font-size: ms(-1);
}