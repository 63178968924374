// ==========================================================================
// Nav Tray Molecule
// ==========================================================================

.m-nav-tray__toggle {
  width: 100%;
  padding: spacing(15 0);
  background: palette(primary);
  text-align: left;

  @include link-hover() {
    cursor: pointer;
  }

  &.is--active {
    .m-nav-tray__title {
      color: palette(white);

      .chevron {
        margin-bottom: spacing(3);
        transform: rotate(-180deg);
      }
    }
  }
}

.m-nav-tray__title {
  color: palette(white);
  font-size: ms(-1);
  font-weight: 600;

  @include respond-to(medium) {
    font-size: ms(0, $thread: desktop);
  }

  .chevron {
    margin-bottom: spacing(2);
    margin-left: spacing(3);
    transition: all 250ms;
  }
}

.m-nav-tray__title-inner {
  font-weight: normal;
}


.m-nav-tray__inner {
  display: none;
  padding: spacing(25 0);
  background: white;

  &.is--open {
    display: block;
  }


}

.m-nav-tray__close {
  color: palette(white);
  font-size: ms(-1);
  font-weight: 600;

  @include respond-to(medium) {
    font-size: ms(0, $thread: desktop);
  }
}
